<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>二维码专区</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="news-detail">
      <h3>{{ name }}</h3>
      <div class="video-info">
        <div id="test"></div>
        <div class="correlation-video">
          <h2>相关视频</h2>
          <ul>
            <li v-for="(item, index) in newCover" :key="index">
              <a @click="clickPlay(item.cc_id, item.name)">
                <el-image
                  v-if="item.imgurl === ''"
                  src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
                />
                <el-image v-if="item.imgurl !== ''" :src="item.imgurl" />
              </a>
              <p
                :title="item.name"
                style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"
              >
                {{ item.name }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCourses } from '../api/courses'
import { getIsPay, getResult } from '../utils/auth'

export default {
  name: 'home',
  data() {
    return {
      cc_id: '',
      type: 1,
      name: '',
      coverVideo: [],
      newCover: []
    }
  },
  methods: {
    createPlayer(cc_id) {
      this.video = document.getElementById('test')
      this.player = window.createCCH5Player({
        vid: cc_id,
        siteid: 'B3900E03A0A4C537',
        autoStart: true, //自动播放
        //realAutoPlay: true, //Chrome等部分浏览器会存限制导致无法自动播放，若需要绕过浏览器限制请使用参数 静音自动播放-realAutoPlay
        playtype: 1,
        width: '100%',
        height: '100%',
        isShare: false,
        banDrag: false,
        open_barrage: false,
        progressbar_enable: 1,
        parentNode: this.video
      })
    },
    setPlayerCallBack() {
      window.onCCH5PlayerLoaded = this.onCCH5PlayerLoaded
      window.on_CCH5player_ready = this.onPlayerReady
      window.on_player_volumechange = this.onPlayerVolumeChange
      window.on_CCH5player_pause = this.onCCH5playerpause
      window.on_CCH5player_play = this.onCCH5playerplay
      window.on_CCH5player_ended = this.onCCH5playerended
    },
    onCCH5PlayerLoaded() {
      window.ccH5PlayerJsLoaded = true
      this.createPlayer(this.cc_id)
    },
    onPlayerReady(obj) {
      //console.log('CCH5player_ready', obj)
      window.cc_js_Player.setVolume(0.3)
    },
    onPlayerVolumeChange(volume, vid) {
      //console.log('设置音量回调:  ', volume, vid)
    },
    onCCH5playerpause() {
      //暂停
      //console.log('CCH5player_pause')
    },
    onCCH5playerplay() {
      //console.log('CCH5player_play')
    },
    onCCH5playerended() {
      //console.log('CCH5player_ended')
    },
    //相关视频
    relevantVideo() {
      let params = {
        term: 1,
        licence_id: this.licence_id,
        province_id: 0,
        city_id: 0,
        jiaxiao_id: 0,
        appid: 1
      }
      getCourses(params).then(res => {
        if (res.code === 0) {
          for (let i = 0; i < res.result.course.length; i++) {
            if (this.subject_id == res.result.course[i].subject_id) {
              this.newCover = this.getRandomArrayElements(
                res.result.course[i].course_list,
                5
              )
            }
          }
        }
      })
    },
    //随机取几个元素
    getRandomArrayElements(arr, count) {
      let shuffled = arr.slice(0),
        i = arr.length,
        min = i - count,
        temp,
        index
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random())
        temp = shuffled[index]
        shuffled[index] = shuffled[i]
        shuffled[i] = temp
      }
      return shuffled.slice(min)
    },
    //点击播放
    clickPlay(cc_id, name) {
      this.cc_id = cc_id
      this.name = name
      this.createPlayer(this.cc_id)
    }
  },
  created() {
    this.cc_id = this.$route.query.cc_id
    this.subject_id = this.$route.query.subject_id
    this.name = this.$route.query.name
    if (getResult() !== undefined) {
      if (JSON.parse(getResult()).qr_code !== '') {
        if (JSON.parse(getResult()).licence_id === 2) {
          this.licence_id = 59
        } else if (JSON.parse(getResult()).licence_id === 3) {
          this.licence_id = 61
        } else if (JSON.parse(getResult()).licence_id === 4) {
          this.licence_id = 60
        }
      } else {
        if (getIsPay() >= 2) {
          if (JSON.parse(getResult()).licence_id === 2) {
            this.licence_id = 59
          } else if (JSON.parse(getResult()).licence_id === 3) {
            this.licence_id = 61
          } else if (JSON.parse(getResult()).licence_id === 4) {
            this.licence_id = 60
          }
        }
      }
    }
    this.setPlayerCallBack()
    this.relevantVideo()
  },
  mounted() {
    if (window.ccH5PlayerJsLoaded) {
      this.createPlayer(this.cc_id)
    }
  },
  destroyed() {
    this.player && this.player.destroy()
  },
  components: {}
}
</script>
